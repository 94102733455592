import React from 'react'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { GroupWithRequired } from '@src/pages/OnboardingChecklist/Performance/common'

export const GeneralSettings = () => {
  const items = {
    required: [
      {
        title: 'Enable skills assessment',
        description:
          'Adds a section in the scorecard to ask for the skills to be assessed during any assessment cycle',
        name: 'enable_skill_assessment',
      },
    ],
    controls: [
      {
        title: 'Enable values assessment',
        description:
          'Adds a section in the scorecard to ask for the values to be assessed. Values are the building blocks of the company culture, so values assessment helps with culture fit assessment.',
        name: 'enable_values',
      },
      {
        title: 'Enable functional management',
        description:
          'A functional manager(FM) is a person with the same skills but a higher seniority compared to the person who is being mentored. When enabled the FM will have to review the mentee during all types of performance cycles.',
        name: 'enable_functional_management',
      },
      {
        title: 'Enable dynamic scorecard deliverables section',
        description:
          'Allows the deliverables section in performance review scorecards to include KPIs or not, based on whether the user has employee KPIs assigned to them.',
        name: 'enable_auto_reviewed_employee_type',
      },
      {
        title: 'Enable segmented deliverables assessment',
        description:
          'Allows the deliverables section in performance review scorecards to have segmented assessment',
        name: 'enable_segmented_deliverables_assessment',
      },
      {
        title: 'Enable scorecard auto-generation',
        description:
          'Enabling this setting will allow the system to automatically process the generation of performance review scorecards prior to the start of the feedback period. This includes a sync task for employee data, as well as employee timeline items.',
        name: 'enabled_scorecards_generations',
      },
    ],
  }

  return (
    <GroupWithRequired requiredItems={items.required} highlightRequired={false}>
      {items.controls.map(({ title, description, name }) => (
        <LapeNewSwitch key={title} itemTypeProps={{ title, description }} name={name} />
      ))}
    </GroupWithRequired>
  )
}
